//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class BaseListDTO<T>
{
	public total: number;
	public page: number;
	public pageSize: number;
	public pageCount: number;
	public items: T[];
}
export class HangfireMediator
{
}
export class ResultOrError<T>
{
	public result: T;
	public error: string;
	public commonError: CommonErrors;
	public isError: boolean;
}
export class InternalError
{
	public commonError: CommonErrors;
	public error: string;
	public isError: boolean;
}
export enum CommonErrors {
	NoError = 0,
	NotFound = 1,
	ServerError = 2,
	Unauthorized = 3
}
export class TemplateDTO
{
	public id: number;
	public name: string;
	public alias: string;
	public layout: string;
	public appType: AppType;
	public isPrivate: boolean;
	public image: string;
	public accounts: TemplateAccountDTO[];
}
export class TemplateAccountDTO
{
	public id: number;
	public name: string;
}
export class NewTemplateRequest
{
	public name: string;
	public alias: string;
	public appType: AppType;
	public layout: string;
	public isPrivate: boolean;
	public image: string;
}
export class EditTemplateRequest
{
	public name: string;
	public alias: string;
	public appType: AppType;
	public layout: string;
	public isPrivate: boolean;
	public image: string;
}
export class CreateScheduleRequest
{
	public accountId: number;
	public channelId: number;
	public playlistId: number;
	public startDate: number;
	public endDate: number;
	public days: number[];
	public startTime: string;
	public endTime: string;
	public variables: ScheduleDataVariables;
}
export class ScheduleDataPresentationVariables
{
}
export class ScheduleDataVariables
{
}
export class ScheduleData
{
	public startDate: any;
	public endDate: any;
	public days: number[];
	public startTime: any;
	public endTime: any;
	public variables: ScheduleDataVariables;
}
export class SchedulesForChannel
{
	public items: ScheduleForChannel[];
}
export class ScheduleForChannel
{
	public id: number;
	public lastModifiedOn: any;
	public scheduleData: ScheduleData;
	public playlistCacheData: PlaylistCacheData;
	public playlistCacheVersion: number;
	public playlistCacheModifiedOn: any;
}
export class UpdateScheduleRequest
{
	public startDate: number;
	public endDate: number;
	public days: number[];
	public startTime: string;
	public endTime: string;
	public variables: ScheduleDataVariables;
}
export class AdminMobileDeviceDto
{
	public id: number;
	public pushToken: string;
	public mobileDeviceType: MobileDeviceType;
	public appId: number;
	public appName: string;
	public isDemo: boolean;
}
export class PushMessage
{
	public platform: MobileDeviceType;
	public token: string;
	public title: string;
	public body: string;
	public isDemo: boolean;
	public isDev: boolean;
	public appName: string;
}
export class PushRegisterDeviceDto
{
	public appAlias: string;
	public deviceToken: string;
	public mobileDeviceType: MobileDeviceType;
	public isDemo: boolean;
	public isDev: boolean;
}
export class SendPushNotificationDto
{
	public appId: number;
	public title: string;
	public body: string;
}
export class CreatePresentationRequest
{
	public accountId: number;
	public name: string;
	public width: number;
	public height: number;
}
export class GetPresentationResponse
{
	public id: number;
	public thumbUri: string;
	public mediaItemType: MediaItemType;
	public name: string;
	public duration: number;
	public width: number;
	public height: number;
	public meta: string;
	public lastModifiedOn: any;
	public isDraft: boolean;
}
export class PreviewPresentationResponse
{
	public id: number;
	public name: string;
}
export class UpdatePresentationRequest
{
	public name: string;
	public duration: number;
	public meta: string;
}
export class AddMediaItemRequest
{
	public mediaItemId: number;
}
export class AddMediaItemToPlaylistsCommand
{
	public mediaItemId: number;
	public playlists: number[];
}
export class AddMediaItemToPlaylistsHandler
{
}
export class CreatePlaylistRequest
{
	public accountId: number;
	public name: string;
	public meta: string;
	public orientation: PlaylistOrientation;
}
export class GetPlaylistResponse
{
	public id: number;
	public accountId: number;
	public accountName: string;
	public name: string;
	public duration: number;
	public orientation: PlaylistOrientation;
	public itemsCount: number;
	public canDelete: boolean;
}
export class PlaylistDetails
{
	public id: number;
	public name: string;
	public meta: string;
	public duration: number;
	public orientation: PlaylistOrientation;
	public canDelete: boolean;
	public items: PlaylistDetailsMediaItem[];
	public schedules: PlaylistSchedules[];
	public devices: PlaylistDevice[];
	public modifiedOn: any;
	public modifiedBy: string;
}
export class PlaylistDevice
{
	public deviceId: number;
	public deviceName: string;
	public lastHeartbeat: any;
}
export class PlaylistSchedules
{
	public channelId: number;
	public channelName: string;
	public scheduleId: number;
	public schedule: string;
}
export class PlaylistDetailsMediaItem
{
	public id: number;
	public mediaItemId: number;
	public mediaItemType: MediaItemType;
	public mediaName: string;
	public mediaThumbUri: string;
	public duration: number;
	public size: number;
	public width: number;
	public height: number;
	public meta: string;
	public originalDuration: number;
	public transition: PlaylistMediaItemTransitionType;
	public order: number;
	public isDisabled: boolean;
}
export class PlaylistItemsOrder
{
	public items: PlaylistItemOrder[];
}
export class PlaylistCacheDTO
{
	public version: number;
	public data: PlaylistCacheData;
}
export class PlaylistCacheMediaItem
{
	public order: number;
	public type: MediaItemType;
	public duration: number;
	public meta: string;
	public name: string;
	public size: number;
	public width: number;
	public height: number;
	public mediaUri: string;
	public thumbUri: string;
}
export class PlaylistCacheData
{
	public id: number;
	public name: string;
	public duration: number;
	public meta: PlaylistMeta;
	public items: PlaylistCacheMediaItem[];
}
export class PlaylistMeta
{
	public effect: string;
	public transition: PlaylistMediaItemTransitionType;
	public duration: number;
}
export class UpdatePlaylistMediaItemRequest
{
	public duration: number;
}
export class UpdatePlaylistRequest
{
	public name: string;
	public meta: string;
	public orientation: PlaylistOrientation;
}
export class PlaylistItemOrder
{
	public id: number;
	public order: number;
}
export class DeviceQueryAnswerRequest
{
	public deviceId: number;
	public commandId: number;
	public response: string;
}
export class DeviceQueryAnswerHandler
{
}
export class GetPlaylistsForChannelResponse
{
	public schedule: string;
	public playlists: GetChannelPlaylistResponse[];
}
export class GetChannelPlaylistResponse
{
	public accountId: number;
	public id: number;
	public name: string;
	public meta: string;
}
export class DeviceInfoDto
{
	public playlistVersions: GetChannelPlaylistVersionResponse[];
	public commands: DeviceCommandResponse[];
}
export class GetDeviceInfoCommand
{
	public deviceId: number;
	public channelId: number;
}
export class GetDeviceInfoCommandHandler
{
}
export class GetEntirePlaylistResponse
{
	public accountId: number;
	public name: string;
	public meta: string;
}
export class GetFindDeviceResponse
{
	public id: number;
	public name: string;
	public platform: DevicePlatform;
	public accountId: number;
	public currentPlaylistId: number;
	public playlistName: string;
}
export class GetChannelPlaylistVersionResponse
{
	public cacheId: number;
	public playlistVersion: number;
	public scheduleLastModifier: any;
}
export class DeviceCommandResponse
{
	public id: number;
	public deviceId: number;
	public command: string;
	public isQuery: boolean;
}
export class DeleteVariable
{
	public key: string;
}
export class DeleteVariableHandler
{
}
export class GetVariable
{
	public key: string;
}
export class GetVariableHandler
{
}
export class GetVariables
{
}
export class GetVariablesHandler
{
}
export class SetVariableRequest
{
	public key: string;
	public value: string;
}
export class SetVariableRequestHandler
{
}
export class VariableDto
{
	public key: string;
	public value: string;
}
export class ActivateMediaItem
{
	public mediaItemId: number;
}
export class ActivateMediaItemHandler
{
}
export class DeactivateMediaItem
{
	public mediaItemId: number;
}
export class DeactivateMediaItemHandler
{
}
export class GenerateThumbnail
{
}
export class GetMediaItemsForAccount
{
	public accountId: number;
	public page: number;
	public pageSize: number;
	public sortBy: string;
	public sortOrder: string;
	public search: string;
	public type: MediaItemType;
}
export class GetMediaItemsForAccountHandler
{
}
export class MediaItemVideoInfo
{
	public probeOutput: ProbeOutput;
	public rawOutput: string;
}
export class GetVideoInfo
{
	public id: number;
}
export class GetVideoInfoHandler
{
}
export class ProbeOutput
{
	public streams: ProbeStream[];
	public format: ProbeFormat;
}
export class ImagesListModel
{
	public items: any[];
}
export class MediaItemListItem
{
	public id: number;
	public name: string;
	public thumbUri: string;
	public mediaItemType: MediaItemType;
	public width: number;
	public height: number;
	public duration: number;
	public size: number;
	public meta: string;
	public lastModifiedOn: any;
	public used: number;
	public isDraft: boolean;
	public activateOn: any;
	public deactivateOn: any;
	public reference: string;
}
export class MediaItemsList extends BaseListDTO<MediaItemListItem>
{
}
export class UploadedMediaItem
{
	public id: number;
	public name: string;
	public uri: string;
}
export class DeleteFromStorageDelegate
{
}
export class MediaItemImageMeta
{
	public uri: string;
}
export class MediaItemVideoMeta
{
	public uri: string;
}
export class GalleryImageDto
{
	public id: number;
	public name: string;
	public width: number;
	public height: number;
	public meta: string;
	public thumbUri: string;
	public size: number;
	public duration: number;
	public mediaItemType: MediaItemType;
}
export class VideoInfo
{
	public id: number;
	public info: string;
}
export class RemoveScheduleForMediaItemCommand
{
	public mediaItemId: number;
}
export class RemoveDeactivationForMediaItemHandler
{
}
export class ScheduleUpdateVideoMetadataCommand
{
	public id: number;
}
export class ScheduleUpdateVideoMetadataHandler
{
}
export class SetActivationForMediaItemCommand
{
	public mediaItemId: number;
	public activateOn: number;
}
export class SetActivationForMediaItemHandler
{
}
export class SetDeactivationForMediaItemCommand
{
	public mediaItemId: number;
	public deactivateOn: number;
}
export class SetDeactivationForMediaItemHandler
{
}
export class UpdateVideoMetadataWebhookDto
{
	public container: string;
	public blobFileName: string;
	public fileName: string;
	public duration: number;
	public width: number;
	public height: number;
	public info: string;
	public thumbUri: any;
}
export class UpdateVideoMetadataCommand
{
	public dto: UpdateVideoMetadataWebhookDto;
}
export class UpdateVideoMetadataHandler
{
}
export class UploadedImageModel
{
	public id: number;
	public uri: string;
	public thumbnailUri: string;
}
export class VideoMeta
{
	public fileName: string;
	public info: string;
	public duration: number;
	public width: number;
	public height: number;
	public frames: number;
	public thumbUri: string;
}
export class ProbeStream
{
	public width: number;
	public height: number;
	public duration: number;
	public nb_frames: number;
}
export class ProbeFormat
{
	public filename: string;
	public duration: number;
}
export class MeDTO
{
	public user: MeUserDTO;
	public accounts: MeAccountDTO[];
}
export class MeUserDTO
{
	public userName: string;
}
export class MeAccountDTO
{
	public id: number;
	public name: string;
	public features: string;
	public userFeatures: string;
	public userAccountFeatures: string;
}
export class DevicePingRequest
{
	public deviceId: any;
	public appAlias: string;
}
export class GuestDirectoryDeviceDto
{
	public id: number;
	public deviceId: any;
	public appId: number;
	public appName: string;
	public platform: AppPlatforms;
	public lastPingedOn: any;
}
export class EditFormDTO
{
	public name: string;
	public alias: string;
	public appId: number;
}
export class FormRequestDTO
{
	public id: number;
	public data: string;
	public state: FormRequestState;
	public deviceId: string;
	public formAlias: string;
	public formName: string;
	public createdOn: any;
	public modifiedOn: any;
	public request: string;
	public response: string;
}
export class NewFormRequestDTO
{
	public data: string;
	public request: string;
	public deviceId: string;
	public appAlias: string;
	public pageId: number;
}
export class FormRequestsList extends BaseListDTO<FormRequestDTO>
{
}
export class EditFormRequestDTO
{
	public state: FormRequestState;
	public response: string;
}
export class PageData
{
	public content: Block[];
}
export class FormDTO
{
	public id: number;
	public name: string;
	public alias: string;
	public accountId: number;
	public appId: number;
	public appName: string;
	public totalRequests: number;
	public incompleteRequests: number;
}
export class NewFormDTO
{
	public name: string;
	public alias: string;
}
export class GetDeviceNotificationsQuery
{
	public deviceId: string;
}
export class GetDeviceNotificationsQueryHandler
{
}
export class LocalizedText
{
	public text: string;
	public lang: string;
}
export class Block
{
	public type: string;
	public text: LocalizedText[];
}
export class DevPlayerData
{
	public data: string;
	public version: number;
}
export class CreateDeviceCommandCommand
{
	public deviceId: number;
	public command: string;
	public isQuery: boolean;
}
export class DeviceCommandCreated
{
	public deviceId: number;
}
export class CreateDeviceCommandCommandHandler
{
}
export class EditDeviceRequest
{
	public name: string;
	public playerVersion: string;
	public deviceInfo: string;
}
export class DeviceLogStatsForAccount
{
	public accountId: number;
	public deviceId: number;
	public date: any;
	public stats: string;
}
export class GetDeviceLogStatsForAccount
{
	public accountId: number;
}
export class GetDeviceLogStatsForAccountHandler
{
}
export class PendingDatesForStatsDto
{
	public dates: any[];
}
export class GetPendingDatesForStats
{
}
export class GetPendingDatesForStatsHandler
{
}
export class RegisterDeviceRequest
{
	public name: string;
	public uid: string;
	public platform: DevicePlatform;
	public playerVersion: string;
	public deviceInfo: string;
}
export class RegisterDeviceResponse
{
	public id: number;
}
export class RunDeviceStatsForDate
{
	public date: string;
}
export class RunDeviceStatsForDateHandler
{
}
export class DeviceLogRequest
{
	public deviceId: number;
	public channelId: number;
	public message: string;
	public exception: string;
	public level: DeviceLogLevel;
}
export class DevicePlaylistChangedEvent
{
	public deviceId: number;
	public playlistId: number;
}
export class DevicePlaylistChangedEventHandler
{
}
export class LogVideoErrorCommand
{
	public videoId: string;
	public deviceId: number;
	public channelId: number;
	public playlistId: number;
}
export class LogVideoErrorCommandHandler
{
}
export class AccountDeviceError
{
	public id: number;
	public deviceId: number;
	public deviceName: string;
	public message: string;
	public exception: string;
	public createdOn: any;
	public level: DeviceLogLevel;
}
export class DevicesForAccount
{
	public devices: DeviceForAccount[];
	public stats: DeviceLogStatsForAccount[];
	public currentVersion: string;
}
export class DeviceForAccount
{
	public deviceId: number;
	public deviceName: string;
	public deviceUID: string;
	public channelId: number;
	public channelName: string;
	public channelGroupName: string;
	public playlistId: number;
	public playlistName: string;
	public lastHeartbeat: any;
	public devicePlatform: DevicePlatform;
	public playerVersion: string;
}
export class AccountDeviceDayStat
{
	public id: number;
	public logs: number;
}
export class AccountDeviceStats
{
	public date: any;
	public stats: AccountDeviceDayStat[];
}
export class DeviceLogDTO
{
	public message: string;
	public exception: string;
	public createdOn: any;
	public level: DeviceLogLevel;
}
export class DashboardDeviceInfoDto
{
	public name: string;
	public platform: DevicePlatform;
	public playerVersion: string;
	public deviceInfo: string;
}
export class DeviceQuery
{
	public commandId: number;
	public query: string;
	public response: string;
	public isExecuted: boolean;
}
export class GetDeviceQueriesQuery
{
	public deviceId: number;
	public pageNo: number;
	public pageSize: number;
}
export class GetDeviceQueriesHandler
{
}
export class GetDevicesResponse
{
}
export class ChannelRepsonse
{
	public id: number;
	public name: string;
	public schedulesCount: number;
}
export class CreateChannelRequest
{
	public name: string;
	public groupName: string;
}
export class ChannelResponse
{
	public id: number;
	public name: string;
	public groupName: string;
	public hasSchedule: boolean;
	public hasDevices: boolean;
	public canDelete: boolean;
}
export class GetChannelInfoResponse
{
	public name: string;
	public groupName: string;
	public accountId: number;
	public playlists: any[];
	public devices: any[];
}
export class UpdateChannelInfoRequest
{
	public name: string;
	public groupName: string;
}
export class AddAppUserCommand
{
	public accountId: number;
	public appId: number;
	public email: string;
	public password: string;
}
export class AddAppUserCommandHandler
{
}
export class CreateAppAndUserCommand
{
	public accountId: number;
	public name: string;
	public alias: string;
	public emails: string[];
	public appType: AppType;
	public password: string;
}
export class CreateAppAndUserCommandHandler
{
}
export class AppsUsersDto
{
	public apps: AppDto[];
}
export class GetAppsUsersCommand
{
	public accountId: number;
}
export class GetAppsUsersCommandHandler
{
}
export class RemoveAppUserCommand
{
	public accountId: number;
	public appId: number;
	public userId: string;
}
export class RemoveAppUserCommandHandler
{
}
export class SetPasswordForUserCommand
{
	public accountId: number;
	public email: string;
	public password: string;
}
export class SetPasswordForUserCommandHandler
{
}
export class UserDto
{
	public id: string;
	public name: string;
	public email: string;
	public type: AppUserType;
}
export class AppDto
{
	public id: number;
	public name: string;
	public users: UserDto[];
}
export class AppDetails
{
	public id: number;
	public name: string;
	public forwardPaymentEmails: string;
	public appType: AppType;
	public platforms: number;
	public alias: string;
	public thumbnail: string;
	public isPublished: boolean;
	public hasPendingDraft: boolean;
	public publishedMeta: VersionDetails;
	public draftMeta: VersionDetails;
	public pages: PageDetails[];
	public templates: TemplateDTO[];
	public forms: FormDTO[];
}
export class AppDTO
{
	public id: number;
	public name: string;
	public accountId: number;
	public accountName: string;
	public forwardPaymentEmails: string;
	public alias: string;
	public appType: AppType;
	public platforms: number;
	public thumbnail: string;
	public data: any;
	public isPublished: boolean;
	public publishedBy: string;
	public publishedOn: any;
	public activeDevices: number;
}
export class AppPageDTO
{
	public id: number;
	public name: string;
	public level: number;
	public order: number;
	public parentPageId: number;
	public versions: any;
	public subpages: any;
	public isHidden: boolean;
	public isLink: boolean;
}
export class AppPageVersionDTO
{
	public versionId: number;
	public template: string;
	public rawData: any;
	public isPublished: boolean;
	public createdOn: any;
	public name: string;
}
export class AppPageForCloneDto
{
	public id: number;
	public name: string;
	public appId: number;
	public appName: string;
	public parentPageId: number;
	public descendants: number;
}
export class AppPageSquareDTO
{
	public pageId: number;
	public title: string;
	public price: number;
	public currency: string;
	public appId: number;
	public appName: string;
	public forwardPaymentEmails: string;
}
export class AppPageSquarePaymentDetailsDTO
{
	public pageId: number;
	public price: number;
	public currency: string;
}
export class PageVersionDataDTO
{
	public data: string;
}
export class CacheVersionDTO
{
	public version: number;
	public data: string;
	public date: any;
}
export class DraftPageDTO
{
	public id: number;
	public name: string;
	public template: string;
	public data: any;
	public isPublished: boolean;
	public level: number;
	public order: number;
	public createdById: string;
	public createdOn: any;
}
export class CacheDraftDTO
{
	public id: number;
	public alias: string;
	public appType: AppType;
	public platforms: number;
	public data: any;
	public pages: DraftPageDTO[];
}
export class CopyPageToAppCommand
{
	public pageId: number;
	public sourcePageId: number;
}
export class CopyPageToAppCommandHandler
{
}
export class GetLastCacheQuery
{
	public alias: string;
}
export class GetLastCacheQueryHandler
{
}
export class GetLastCacheVersionQuery
{
	public alias: string;
}
export class GetLastCacheVersionQueryHandler
{
}
export class GetPagesFromAppForCopying
{
	public appId: number;
}
export class GetPagesFromAppForCopyingHandler
{
}
export class GetPagesFromAppForLinking
{
	public appId: number;
}
export class GetPagesFromAppForLinkingHandler
{
}
export class LinkPageToAppCommand
{
	public pageId: number;
	public sourcePageId: number;
}
export class LinkPageToAppCommandHandler
{
}
export class NewAppDTO
{
	public name: string;
	public alias: string;
	public forwardPaymentEmails: string;
	public thumbnail: string;
	public appType: AppType;
	public platforms: number;
	public accountId: number;
}
export class UpdateAppDTO
{
	public name: string;
	public forwardPaymentEmails: string;
	public thumbnail: string;
	public rawData: any;
}
export class NewPageRequestDTO
{
	public name: string;
}
export class NewPageResponseDTO
{
	public id: number;
}
export class NewSubpageRequestDTO
{
	public name: string;
	public sourceId: number;
}
export class NewSubpageResponseDTO
{
	public id: number;
}
export class PageOrder
{
	public pageId: number;
	public order: number;
	public name: string;
}
export class ReorderRequestDTO
{
	public parentPageId: number;
	public template: string;
	public name: string;
	public pages: PageOrder[];
}
export class ReorderResponseDTO
{
}
export class UpdatePageRequestDTO
{
	public name: string;
	public template: string;
	public rawData: any;
}
export class UpdatePageResponseDTO
{
	public id: number;
}
export class VersionDetails
{
	public versionId: number;
	public data: any;
}
export class PageDetails
{
	public id: number;
	public name: string;
	public version: number;
	public level: number;
	public order: number;
	public parentPageId: number;
	public isNew: boolean;
	public isDraft: boolean;
	public isHidden: boolean;
	public isLink: boolean;
}
export class AdminAppDto
{
	public id: number;
	public name: string;
	public accountId: number;
	public accountName: string;
	public createdBy: string;
	public users: AdminAppUserDto[];
}
export class AdminAppUserDto
{
	public id: string;
	public userName: string;
}
export class GetAdminAppsResponse
{
	public apps: AdminAppDto[];
}
export class AdminAccountDetailsDto
{
	public id: number;
	public name: string;
	public alias: string;
	public features: string;
	public users: AdminAccountDetailsUserDto[];
}
export class AdminAccountDetailsUserDto
{
	public id: string;
	public userName: string;
	public features: string;
}
export class AdminAccountDto
{
	public name: string;
	public alias: string;
	public id: number;
}
export class EditAdminAccountDto
{
	public name: string;
	public alias: string;
	public features: string;
}
export class AdminAccountOptionsDto
{
	public id: number;
	public name: string;
	public forwardAllFormsTo: string;
	public automaticReplyAllForms: string;
}
export class EditAdminAccountOptionsDto
{
	public forwardAllFormsTo: string;
	public automaticReplyAllForms: string;
}
export class AdminAccountUserDto
{
	public email: string;
	public role: AccountUserRole;
}
export class GetAdminAccountUsersQuery
{
	public accountId: number;
}
export class GetAdminAccountUsersQueryHandler
{
}
export enum Features {
	mobileapps = 0,
	digitalsignage = 1,
	legacymobile = 2
}
export class AccountDetails
{
	public id: number;
	public name: string;
	public alias: string;
	public users: AccountUser[];
}
export class AccountUser
{
	public userId: string;
	public email: string;
	public userName: string;
	public role: AccountUserRole;
}
export class AccountForUser
{
	public id: number;
	public name: string;
}
export class AccountOptions
{
	public forwardAllFormsTo: string;
	public automaticReplyAllForms: string;
}
export class EditAccountDTO
{
	public name: string;
	public alias: string;
}
export enum AccountUserRole {
	Admin = 0,
	User = 1,
	Device = 2
}
export enum MobileDeviceType {
	Unknown = 0,
	Android = 1,
	iOS = 2
}
export enum AppType {
	Unknown = 0,
	GuestDirectory = 1,
	Mobile = 2
}
export enum AppPlatforms {
	None = 0,
	iPhone = 1,
	iPad = 2,
	Android = 4,
	AndroidTablet = 8,
	HTML5 = 16,
	UWP = 32
}
export enum AppUserType {
	Admin = 0,
	MyvaUser = 1
}
export enum FormRequestState {
	Sent = 0,
	Acknowledged = 1,
	Completed = 2
}
export enum ImageTarget {
	None = 0,
	Background = 1,
	Gallery = 2,
	Picker = 3
}
export enum DevicePlatform {
	Unknown = 0,
	WPF = 1,
	Android = 2,
	UWP = 3,
	Html = 4
}
export enum DeviceLogLevel {
	Trace = 0,
	Debug = 1,
	Information = 2,
	Warning = 3,
	Error = 4,
	Critical = 5,
	None = 6
}
export enum MediaItemType {
	Unknown = 0,
	Presentation = 1,
	Image = 2,
	Video = 3
}
export enum MediaItemTarget {
	DigitalSignage = 0,
	Mobile = 1
}
export enum PlaylistMediaItemTransitionType {
	None = 0,
	SlideLeft = 1
}
export enum PlaylistOrientation {
	Landscape = 0,
	Portrait = 1
}
